<template>
    <v-container id="cart">
        <v-row align="center" class="mb-5">
            <v-col cols="auto">
                <v-img width="31" src="../assets/img/cart-black.svg" alt=""></v-img>
            </v-col>
            <v-col>
                <h1>
                    Cesta de compra
                </h1>
            </v-col>
        </v-row>
        <template v-if="cart.length > 0">
            <v-row v-for="(product,i) in cart" :key="i" class="product-row">
                <v-col cols="auto" >
                    <v-img class="product-image" aspect-ratio="1" :src="'https://rawkv3.actstudio.xyz/storage/uploads/'+product.image.path"></v-img>
                </v-col>
                <v-col class="product-info"> 
                    <v-row>
                        <v-col>
                            <h2>{{product.name}}</h2>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12" sm="" class="pb-1">
                            <p>
                                <span>Talla:</span>
                                 {{product.size}}
                            </p>
                        </v-col>
                        <v-col cols="12" sm="" class="pb-1">
                            <p>
                                <span>Cantidad:</span>
                                 {{product.quantity}}
                            </p>
                        </v-col>
                        <v-col cols="12" sm="" class="pb-1">
                            <p>
                                <span>Precio: </span>
                                 ${{product.price}}
                            </p>
                        </v-col>
                        <v-col cols="12" sm="" class="pb-1">
                            <p>
                                <span>Total: </span>
                                 ${{calcItemTotal(product.quantity, product.price)}}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5">
                        <v-col>
                            <p class="remove-product" @click="removeItem(i)" tabindex="0">Eliminar producto</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="cart-total-row mb-7">
                <v-col cols="12">
                    <hr>
                </v-col>
               
                <v-col cols="12" align="center">
                    <p> Subtotal: ${{numberWithCommas(subTotal)}}</p>
                </v-col>
                <v-col cols="12"  align="center">
                    <router-link class="button-primary" :to="'/checkout'">
                            Proceder a la compra
                    </router-link>
                </v-col>
            </v-row>
        </template>   
        <template v-else>
            <v-row class="no-products" align="center">
                <v-col cols="12" align="center" >
                    <h2>
                        Tu cesta está vacía, comienza a agregar productos.
                    </h2>
                </v-col>
                <v-col cols="12" align="center">
                    <router-link class="button-primary" :to="'/tienda'">
                        Ir a la tienda
                    </router-link>
                </v-col>
            </v-row>
        </template> 
    </v-container>
</template>
<script>
export default {
    data(){
        return {
            //cart: []
        }
    },
    props: {
        cart: Array
    },
    mounted: function(){
        setTimeout(() => {
            document.getElementById('footer').classList.add('inCart')
        }, 500);
    },
    methods: {

        calcItemTotal: function(quantity, price){
            return this.numberWithCommas(quantity * price);
        },

        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        removeItem: function(index){
            this.$root.$emit('removeProductfromCart', index);
        }
    },
    computed: {
        subTotal: function(){
            let total = 0;
            this.cart.forEach(item => {
                total += item.quantity * item.price;
            });

            return total;
        },
    },
    beforeDestroy: function(){
        document.getElementById('footer').classList.remove('inCart');
    }
}
</script>